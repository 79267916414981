.log-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 40px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    background-color: #f1f1f1;
    /* border-top: 1px solid #ccc; */
}

.log-container.open {
    max-height: 200px;
}

.log-container .log-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #c7282b;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
}

.log-container .log-content {
    padding: 10px;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
}

.log-container .log-entry {
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
    color:#000;
    font-size: 14px;
}

.log-container button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
}