.body-container {
    display: flex;
    gap: 100px;
}

.body-container .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.body-container .title {
    font-size: 24px;
    text-align: center;
}

.body-container .desc {
    font-size: 20px;
}

.body-container .buttons {
    display: flex;
    gap: 20px;
}

.body-container .button {
    padding: 20px;
    min-width: 120px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.body-container .button:first-child {
    background-color: var(--btn);
    color: var(--text);
}

.body-container .brands {
    width: 500px;
    height: 50px;
    position: relative;
    filter: grayscale(100%);
}

.body-container .imageContainer {
    flex: 1;
    position: relative;
}

@media (max-width: 1024px) {
    .body-container {
        flex-direction: column;
        text-align: center;
    }

    .body-container .buttons {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .body-container .title {
        font-style: 64px;
    }

    .body-container .brands {
        width: 100%;
    }
}

.box-container {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.box-container-EDB {
    display: grid;
    justify-content: center;
    margin: 20px;
    margin-top: 30px;
    text-align: center;
}

.margintop10 {
    margin: 10px 0;
}

.download-edb-tmpl {
    display: flex;
    font-size: 10px;
    text-align: right;
    flex-direction: column;
    cursor: pointer;
    color: #bbb5b6;
}

.box-container-EDB input[type="text"]::placeholder {
    color: #aea6a6e3;
  }

.fieldgroup {
    float: left;
    width: auto;
    margin-left: 3em;
}

.fieldgroup input {
    margin-right: 0.3em;
}

.file-submit-btn {
    padding: 20px;
    min-width: 120px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: var(--btn);
    color: var(--text);
    margin: 0 20px;
}

.file-submit-btn:hover {
    background-color: var(--btn-hover);
    color: var(--text);
}

.process-pdf-submit {
    display: none;
}

.checkbox-disabled {
    color: #9d9b9b;
}

.info-msg {
    position: relative;
    bottom: 10px;
    color: #fff;
    cursor: pointer;
}

.main-page-checkbox-hide {
    display: none;
}