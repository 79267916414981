:root {
  --bg: #0d0c22;
  --bgSoft: #2d2b42;
  --text: white;
  --textSoft: #e5e5e5;
  --btn: rgb(199, 40, 43);
  --btn-hover: gray;
  --footer: gray;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* background-color: var(--bg); */
  /* background-image: url("../img/Background_Image_Search.png"); */
  /* background-image: url("../img/289454ec-7e1f-4c3d-afbf-e2e0ff8adc47.png"); */
  /* background-image: url("../img/Discovery_TeamsBackground_Molecule41.png"); */
  /* background-image: url("../img/Untitled1.png"); */
  /* background-image: url("../img/Discovery_TeamsBackground_Molecule21.png"); */
  background-size: cover;
  color: var(--text);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}


.top-container {
  /* background-color: rebeccapurple; */
  /* width: 1536px; */
  margin: auto;
  /* padding-left: 50px;
  padding-right: 50px; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
}