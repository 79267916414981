.footer-container {
    /* display: flex;
    justify-content: space-between; */
    height: 25px;
    color: var(--footer);
    /* align-items: center; */
    text-align: center;
    color: var(--text);
}

.footer-container .logs-close {
    position:relative;
    bottom: 38px;
}

.footer-container .logs-open {
    position:relative;
    bottom: 38px;
}

.footer-container .logo {
    font-weight: bold;
    filter: brightness(0) invert(1);
}

.footer-container .text {
    font-size: 12px;
}

@media (max-width:768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
}