.curtain-screen {
    background: #c7bfbf5c;
    width: 100%;
    height: 100%;
    z-index: 4;
    position: fixed;
    top: 0px;
}

.loading-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}