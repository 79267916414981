.toastmsg {
    position: fixed;
    top: 60px;
    right: 25px;
    max-width: 300px;
    background: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 1023;
    animation: slideInRight 0.3s ease-in-out forwards,
        fadeOut 0.15s ease-in-out forwards 3s;
    transform: translateX(110%);
}

.toastmsg.closing {
    animation: slideOutRight 0.15s ease-in-out forwards;
}

.toast-progress {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: #b7b7b7;
    animation: toastProgress 3s ease-in-out forwards;
}

.toast-content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toast-icon {
    padding: 0.35rem 0.5rem;
    font-size: 1.5rem;
}

.toast-message {
    flex: 1;
    font-size: 0.9rem;
    /* color: #000000;  */
    color: #fff;
    padding: 0.5rem;
}

.toastmsg.toast-success {
    background: #95eab8;
}

.toastmsg.toast-success .toast-progress {
    background-color: #2ecc71;
}

.toastmsg.toast-danger {
    background: #efaca5;
}

.toastmsg.toast-danger .toast-progress {
    background-color: #e74c3c;
}

.toastmsg.toast-info {
    background: #bddaed;
}

.toastmsg.toast-info .toast-progress {
    background-color: #3498db;
}

.toastmsg.toast-warning {
    background: #ead994;
}

.toastmsg.toast-warning .toast-progress {
    background-color: #f1c40f;
}

@keyframes slideInRight {
    0% {
        transform: translateX(110%);
    }

    75% {
        transform: translateX(-10%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0%);
    }

    25% {
        transform: translateX(-10%);
    }

    100% {
        transform: translateX(110%);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes toastProgress {
    0% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}