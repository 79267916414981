.compareViewer .popup {
    /* display: none; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #000;
    z-index: 1;
}

.compareViewer .popup-content {
    background-color: #fefefe;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    /* max-width: 600px; */
    text-align: center;
    max-height: 90%;
    overflow-y: auto;
}

.compareViewer .tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 100%;
}

.compareViewer .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
}

.compareViewer .tab button:hover {
    background-color: #ddd;
}

.compareViewer .tab button.active {
    background-color: #ccc;
}

.compareViewer .tabcontent {
    /* display: none; */
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
    /* overflow-x: hidden; */
}

.compareViewer .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: relative;
    top: -15px;
}

.compareViewer .close:hover,
.compareViewer .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.compareViewer .loading-circle_inner {
    /* display: none; */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    animation: spin 1s linear infinite;
    position: relative;
    top: 50%;
    left: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.compareViewer .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.compareViewer .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    cursor: pointer;
}

.compareViewer .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
}
.csv-preview {
    overflow-x: scroll;
}

.csv-preview table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.csv-preview th,
.csv-preview td {
    /* border: 1px solid black; */
    padding: 8px;
    text-align: center;
    padding: .75rem;
    vertical-align: top;
    /* border-top: 1px solid #dee2e6; */
}

.csv-preview th {
    background-color: lightblue;
}

.csv-preview tr:nth-child(even) {
    background-color: #f2f2f2;
}

.csv-preview tr:nth-child(odd) {
    background-color: #ffffff;
}

.download-bill-label {
    cursor: pointer;
    color: #898080;
}

.downloadBtn_report,
.downloadBtn_ocr {
    cursor: pointer;
}

.config-writer-class .flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* Adjust the gap between columns as needed */
}

.config-writer-class .flex-item {
    flex: 1 0 30%;
    /* Adjust the width of each column as needed */
    background-color: #f0f0f0;
    padding: 20px;
    box-sizing: border-box;
}

.config-writer-class .flex-item-inner {
    flex: 1 0 30%;
    /* Adjust the width of each column as needed */
    padding: 20px;
    box-sizing: border-box;
}


/* Header styles */
.config-writer-class .flex-header {
    background-color: #007bff;
    color: white;
    padding: 10px;
    text-align: center;
}

/* Body styles */
.config-writer-class .flex-body {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.config-writer-class .rpt-vendor-cfg-lb,
.config-writer-class .bill-vendor-cfg-lb {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 24px;
}

.config-writer-class .rpt-vendor-cfg-lb-container,
.config-writer-class .bill-vendor-cfg-lb-container {
    margin-bottom: 24px;
}

.config-writer-class .rpt-vendor-cfg-container,
.config-writer-class .bill-vendor-cfg-container {
    max-height: 180px;
    overflow-y: auto;
}

.config-writer-class .compare-match-outer {
    margin: 10px 0;
}

.config-writer-class .match-vendor-btn {
    padding: 8px 20px;
    width: 100%;
    cursor: pointer;
    background-color: var(--btn);
    color: var(--text);
    border: 0px;
    border-radius: 10px;
}

.config-writer-class .match-vendor-btn:hover {
    background-color: var(--btn-hover);
    color: var(--text);
}

.config-writer-class .download-match-vendor-btn {
    padding: 8px 20px;
    width: 100%;
    cursor: pointer;
    background-color: var(--btn);
    color: var(--text);
    border: 0px;
    border-radius: 10px;
}

.config-writer-class .download-match-vendor-btn:hover {
    background-color: var(--btn-hover);
    color: var(--text);
}

.config-writer-class .vbmatch-outer-container {
    height: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: auto;
}

.config-writer-class .save-match-btn {
    padding: 8px 20px;
    width: 100%;
    cursor: pointer;
    background-color: var(--btn);
    color: var(--text);
    border: 0px;
    border-radius: 10px;
}

.config-writer-class .save-match-btn:hover {
    background-color: var(--btn-hover);
    color: var(--text);
}

.config-writer-class .skipmatching-container {
    text-align: left;
    align-self: flex-end;
}

.config-writer-class .compare-btn-outer {
    text-align: right;
    align-self: flex-end;
}

.config-writer-class .compare-btn {
    min-width: 120px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: var(--btn);
    color: var(--text);
    margin: 0 20px;
    padding: 8px 20px;
}

.config-writer-class .compare-btn:hover {
    background-color: var(--btn-hover);
    color: var(--text);
}

.rpt-vendor-cfg-container div,
.bill-vendor-cfg-container div {
    /* border-bottom: 1px solid black; */
    margin: 10px 0px;
    cursor: pointer;
}

.rpt-vendor-cfg-container .active,
.bill-vendor-cfg-container .active {
    background-color: #2a972c;
}

.rpt-vendor-cfg-container div:hover,
.bill-vendor-cfg-container div:hover {
    background-color: #888;
}

.vbmatch-outer-container table {
    width: 100%;
    border-collapse: collapse;
    min-height: 200px;
    max-height: 200px;
    overflow-y: auto;
}

.vbmatch-outer-container table tr {
    height: 50px;
}

.vbmatch-outer-container table tr th, .vbmatch-outer-container table tr td {
    border: 1px solid #000;
    padding: 8px;
}

.skip-match-in-check {
    margin: 0 5px 0 0;
}